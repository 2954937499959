import { ConfigurableProduct, Money, PriceRange } from '@magentoTypes'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useFormatPrice } from '~/hooks/useFormatPrice'

const PriceSection: React.FC<{
  hasSale?: boolean
  productPrice?: PriceRange
  product?: ConfigurableProduct
  hideFromSuffix?: boolean
}> = ({ hasSale, productPrice, product, hideFromSuffix = false }) => {
  const { formatPrice } = useFormatPrice()
  const { t } = useTranslation()

  if (!productPrice && !product?.price_range) {
    return null
  }

  return (
    <div className="text-center font-extralight">
      {hasSale ? (
        ['benuta_cz', 'benuta_pl', 'benuta_dk'].includes(
          process.env.NEXT_PUBLIC_STORE_CODE ?? 'benuta_de',
        ) ? (
          <>
            <div className="text-[15px] leading-[18px] line-through lg:text-15-22-sg">
              {formatPrice(
                productPrice
                  ? productPrice?.minimum_price?.regular_price
                  : (product?.price_range?.minimum_price?.regular_price as Money),
              )}
            </div>
            <div className="w-fit bg-sg-neon-yellow px-1 text-[15px] font-extralight leading-[18px] lg:text-15-22-sg">
              {!hideFromSuffix && t('from')}{' '}
              {formatPrice(
                productPrice
                  ? productPrice?.minimum_price?.final_price
                  : (product?.price_range?.minimum_price?.final_price as Money),
              )}
            </div>
          </>
        ) : (
          <>
            <span className="mr-2.5 whitespace-nowrap bg-sg-neon-yellow px-1 text-[15px] font-extralight leading-[18px] lg:text-15-22-sg">
              {!hideFromSuffix && t('from')}{' '}
              {formatPrice(
                productPrice
                  ? productPrice?.minimum_price?.final_price
                  : (product?.price_range?.minimum_price?.final_price as Money),
              )}
            </span>
            <span className="text-[15px] leading-[18px] line-through lg:text-15-22-sg">
              {formatPrice(
                productPrice
                  ? productPrice?.minimum_price?.regular_price
                  : (product?.price_range?.minimum_price?.regular_price as Money),
              )}
            </span>
          </>
        )
      ) : (
        <span className="text-[15px] leading-[15px] lg:text-15-22-sg">
          {!hideFromSuffix && t('from')}{' '}
          {formatPrice(
            productPrice
              ? productPrice?.minimum_price?.regular_price
              : (product?.price_range?.minimum_price?.regular_price as Money),
          )}
        </span>
      )}
    </div>
  )
}

export default PriceSection
