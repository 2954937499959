import tailwindConfig from '../theme'
import { useEffect, useState } from 'react'

export const useMedia = (size: keyof typeof tailwindConfig['theme']['screens']): boolean | null => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    function handleSize() {
      setWidth(window.innerWidth)
    }
    handleSize()
    window.addEventListener('resize', handleSize)
    return function () {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  return width ? width >= parseInt(tailwindConfig.theme.screens[size], 10) : false
}
