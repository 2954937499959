import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useProductAttributes } from '~/hooks/useProductAttributes'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type BrandType = {
  manufacturer: number
}

export const useBrandType = ({ manufacturer }: BrandType) => {
  const { findLabel } = useProductAttributes()
  const router = useRouter()
  const brand = findLabel({ attributeCode: 'manufacturer', attributeValue: manufacturer })

  const getIconAndRoute = (slug: string) => {
    const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ''
    let route = `/${slug}.html`

    if (slug === 'lytte') {
      const locale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE

      switch (locale) {
        case 'de-DE':
        case 'de-AT':
          route = '/lytte-kinderteppiche.html'
          break
        case 'de':
          route = '/de/lytte-kinderteppiche.html'
          break
        case 'da-DK':
          route = '/lytte-bornetaepper.html'
          break
        case 'nl-NL':
          route = '/lytte-kindertapijten.html'
          break
        case 'en-EU':
        case 'en-GB':
          route = '/lytte-kids-rugs.html'
          break
        case 'fr-FR':
          route = '/lytte-tapis-enfants.html'
          break
        case 'fr':
          route = '/fr/lytte-tapis-enfants.html'
          break
        case 'it-IT':
          route = '/lytte-tappeti-bambini.html'
          break
        case 'it':
          route = '/it/lytte-tappeti-bambini.html'
          break
        case 'pl-PL':
          route = '/lytte-dywany-dzieciecy.html'
          break
        case 'es-ES':
          route = '/lytte-alfombras-ninos.html'
          break
        case 'sv-SE':
          route = '/lytte-barnmattor.html'
          break
        case 'cs-CZ':
          route = '/todo-must-be-replaced.html'
          break
        case 'pt-PT':
          route = '/todo-must-be-replaced.html'
          break
        case 'fi-FI':
          route = '/todo-must-be-replaced.html'
          break
        case 'nn-NO':
          route = '/todo-must-be-replaced.html'
          break
      }
    }

    return {
      name: capitalize(slug),
      svg: `/images/brands/${slug}.svg`,
      route,
    }
  }

  if (brand.toLowerCase().includes('pure')) return getIconAndRoute('pure')
  else if (brand.toLowerCase().includes('finest')) return getIconAndRoute('finest')
  else if (brand.toLowerCase().includes('pop')) return getIconAndRoute('pop')
  else if (brand.toLowerCase().includes('lytte')) return getIconAndRoute('lytte')
  else if (brand.toLowerCase().includes('nest')) return getIconAndRoute('nest')
  else return null
}
